<template>
  <start-page-template>
    <sign-in-form :loading="isLoading" :authentication-error="formError" @login="loginHandler" />
    <cookie-consent-new-design class="cookie-consent" />
  </start-page-template>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import StartPageTemplate from '@/components/templates/StartPageTemplate';
import SignInForm from '@/components/start-page/SignInForm';
import CookieConsentNewDesign from '@/modules/dashboard/components/common/CookieConsentNewDesign';

import { getUserWithUsername } from '@/api';

import { getDashboardLocale } from '@/utils';
import { prefetchRequiredContexts } from '@/utils/prefetchRequiredContexts';
import { hasResponseErrors } from '@/utils/storeActionsUtils';

import { LocalStorageService } from '@/services/LocalStorageService';

import rootTypes from '@/store/types';
import questionnaireTypes from '@/modules/questionnaire/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'SignInPage',
  components: {
    StartPageTemplate,
    SignInForm,
    CookieConsentNewDesign
  },
  data() {
    return {
      formError: ''
    };
  },
  computed: {
    ...mapGetters({
      newDesignUsername: rootTypes.getters.GET_USERNAME,
      pdfSummary: dashTypes.getters.PDF_SUMMARY,
      isLoading: rootTypes.getters.GET_LOADING
    })
  },
  async mounted() {
    await this.prefetchDashboardResources();

    if (!this.newDesignUsername || !LocalStorageService.getDashboardToken()) {
      return;
    }

    this.setLoading(true);

    await this.resetQuestionnaire();

    const { data: user } = await getUserWithUsername(this.newDesignUsername);

    if (!user.id) {
      this.setLoading(false);

      return;
    }

    this.setUser(user);
    this.setNewDesignUsername(null);

    await this.setLocale(getDashboardLocale(user.locale));
    await this.loadPriceSettings();

    this.setLoading(false);

    this.redirectToNextPage();
  },
  methods: {
    ...mapMutations({
      setUser: rootTypes.mutations.SET_USER,
      setNewDesignUsername: rootTypes.mutations.SET_USERNAME,
      setIsDashboardLocked: dashTypes.mutations.SET_IS_DASHBOARD_LOCKED
    }),
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      login: rootTypes.actions.LOGIN,
      resetQuestionnaire: questionnaireTypes.actions.RESET_MODULE,
      setLocale: rootTypes.actions.SET_LOCALE,
      loadPriceSettings: dashTypes.actions.LOAD_PRICE_SETTING
    }),
    async loginHandler({ username, password }) {
      this.setLoading(true);

      const response = await this.login({
        username,
        password
      });

      this.setLoading(false);

      if (response.shouldUseTwoFactorAuthentication) {
        await this.$router.push({
          name: 'TwoFactorAuthentication',
          params: { username, password }
        });

        return;
      }

      if (hasResponseErrors(response)) {
        this.formError = this.$t('dashboard.landing.wrongEmailOrPassword');

        return;
      }

      this.redirectToNextPage();
    },
    redirectToNextPage() {
      this.setIsDashboardLocked(false);

      if (this.pdfSummary) {
        this.$router.push({
          name: 'PatientCard',
          params: {
            id: this.pdfSummary.patientId
          }
        });

        return;
      }

      this.$router.push({ name: 'Dashboard' });
    },
    async prefetchDashboardResources() {
      const icons = require.context('@/assets/images/dashboard/icons', true);
      const templates = require.context('@/assets/images/dashboard/notification-templates', true);
      const patients = require.context('@/assets/images/dashboard/patients', true);
      const picto = require.context('@/assets/images/dashboard/PICTO', true);
      const dashboard = require.context('@/assets/images/dashboard', false);
      const doctor = require.context('@/assets/images/doctor', true);
      const photoInstructions = require.context('@/assets/images/photo-instructions-icons', true);

      prefetchRequiredContexts([
        icons,
        templates,
        patients,
        picto,
        dashboard,
        doctor,
        photoInstructions
      ]);
    }
  },
  metaInfo: {
    title: 'Sign in',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
}
</style>
