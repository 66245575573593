<template>
  <div class="sign-in-form">
    <h2
      class="sign-in-form__heading u-typography-gilroy u-text-display  u-text-display--m u-text-display--bold"
    >
      {{ $t('dashboard.landing.button.sigIn') }}
    </h2>
    <p class="sign-in-form__info u-typography-gilroy u-text u-text--m">
      {{ $t('dashboard.landing.thisFormOnly') }}
    </p>
    <u-form ref="signInForm" class="form" @submit.prevent="onSubmit">
      <u-input
        v-model="login"
        data-autotest-id="login-field"
        type="text"
        :label="$t('dashboard.landing.input.email')"
        :validators="requiredFieldValidators"
      />
      <u-input
        v-model="password"
        data-autotest-id="password-field"
        class="form__password"
        type="password"
        :label="$t('dashboard.landing.input.password')"
        :validators="requiredFieldValidators"
      />
      <router-link
        class="sign-in-form__link u-typography-gilroy u-text u-text--link u-text--m"
        :to="{ name: 'ForgotPassword' }"
      >
        {{ $t('dashboard.landing.forgotPassword') }}
      </router-link>
      <u-button
        class="form__button"
        data-autotest-id="sign-in-button"
        type="submit"
        :loading="loading"
        >{{ $t('dashboard.landing.button.sigIn') }}</u-button
      >

      <div
        v-if="authenticationError"
        class="form__error-message u-typography-gilroy u-text u-text--xs"
      >
        <u-icon name="alert-triangle" />
        {{ authenticationError }}
      </div>
    </u-form>
  </div>
</template>

<script>
import { UButton, UForm, UInput, UIcon } from 'universkin-shared';
import { getRequiredValidator } from 'universkin-shared/src/utils/validators';

export default {
  name: 'SignInForm',
  components: { UButton, UForm, UInput, UIcon },
  props: {
    authenticationError: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      login: '',
      password: ''
    };
  },
  computed: {
    requiredFieldValidators() {
      return [getRequiredValidator(this.$t('dashboard.authentication.filedRequired'))];
    }
  },
  methods: {
    onSubmit() {
      this.$emit('login', { username: this.login, password: this.password });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';
@import '~universkin-shared/src/assets/styles/scss/sizes';

.sign-in-form {
  &__heading {
    margin: 0 0 10px 0;
  }

  &__info {
    color: $color-grey600;
    margin: 0 0 37px 0;
  }

  &__link {
    color: $color-graphite;
    margin: 18px 0 33px 0;
  }
}

.form {
  display: flex;
  flex-direction: column;

  &__password {
    margin-top: 24px;
  }

  &__error-message {
    color: $color-error;
    --u-icon-color: #{$color-error};
    margin-top: 5px;
    text-align: center;
  }
}

@media (min-width: $desktop-start) {
  .sign-in-form {
    max-width: 325px;

    &__info {
      margin-bottom: 33px;
    }

    &__link {
      margin: 32px 0 30px 0;
    }
  }

  .form {
    &__password {
      margin-top: 22px;
    }
  }
}
</style>
