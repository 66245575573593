import { flatten, isEmpty } from 'lodash';

const importAll = requireFiles => requireFiles.keys().map(requireFiles);

const getPrefetchLinksFromRequiredContext = requiredContext => {
  const allImports = importAll(requiredContext);
  const files = allImports.filter(file => !isEmpty(file));

  return files.map(file => {
    const link = document.createElement('link');

    link.href = file;
    link.rel = 'prefetch';

    return link;
  });
};

export const prefetchRequiredContexts = requiredContexts => {
  const linkArrays = requiredContexts.map(getPrefetchLinksFromRequiredContext);
  const prefetchLinks = flatten(linkArrays);

  const headNodes = [...document.head.childNodes];
  const documentLinks = headNodes.filter(({ href, rel }) => href && rel && rel === 'prefetch');

  const uniquePrefetchLinks = prefetchLinks.filter(
    ({ href }) => !documentLinks.some(({ href: existingHref }) => href === existingHref)
  );

  document.head.append(...uniquePrefetchLinks);
};
