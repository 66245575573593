<template>
  <div v-if="shouldDisplayCookieConsent" class="cookie-consent flex--center--center">
    <div
      v-if="displayShortDescription"
      class="cookie-consent__short-description cookie-short-description"
    >
      {{ shortConsentDescription }}
    </div>

    <i18n v-else path="cookiesBanner.label" tag="div" class="cookie-consent__full-description">
      <a class="consent-link" href="https://www.skinxs.com/patient-notice" target="_blank">
        skinxs.com/patient-notice
      </a>
      <a class="consent-link" href="https://www.skinxs.com/doctor-notice" target="_blank">
        skinxs.com/doctor-notice
      </a>
    </i18n>

    <div class="cookie-consent__actions actions">
      <u-button
        v-if="displayShortDescription"
        class="actions__see-more-button"
        kind="text"
        size="small"
        @click="showFullDescription"
      >
        {{ $t('cookiesBanner.seeMore') }}
      </u-button>

      <u-button
        class="actions__confirm-button"
        kind="secondary"
        size="small"
        @click="updateAcceptCookies"
      >
        {{ $t('cookiesBanner.action') }}
      </u-button>
    </div>
  </div>
</template>

<script>
import { LocalStorageService } from '@/services/LocalStorageService';
import { UButton } from 'universkin-shared';

const SHORT_DESCRIPTION_SYMBOLS_COUNT = 141;

export default {
  name: 'CookieConsentNewDesign',
  components: { UButton },
  data() {
    return {
      displayShortDescription: true,
      displayCookieConsent: true,
      acceptCookies: false
    };
  },
  computed: {
    shouldDisplayCookieConsent() {
      return !this.acceptCookies && this.displayCookieConsent;
    },
    shortConsentDescription() {
      return this.$t('cookiesBanner.label').slice(0, SHORT_DESCRIPTION_SYMBOLS_COUNT);
    }
  },
  mounted() {
    this.acceptCookies = LocalStorageService.getAcceptCookiesConsent();
  },
  methods: {
    showFullDescription() {
      this.displayShortDescription = false;
    },
    updateAcceptCookies() {
      LocalStorageService.setAcceptCookiesConsent(true);
      this.displayCookieConsent = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/colors';

.consent-link {
  text-decoration: underline;
  color: $color-grey900;
  cursor: pointer;
}

.cookie-consent {
  width: 100%;
  padding: 25px 53px 25px 40px;
  min-height: 90px;
  background: $color-grey100;

  &__short-description,
  &__full-description {
    width: 100%;
    font-size: 14px;
    font-family: var(--u-font-family-Helvetica);
    letter-spacing: 0.4px;
    line-height: 20px;
    color: $color-grey900;
  }
}

.actions {
  display: flex;
  align-items: center;

  &__see-more-button {
    min-width: 240px;
    color: $color-grey900;
    text-transform: uppercase;
  }

  &__confirm-button {
    margin: 0 25px;
    min-width: 280px;
  }
}

@media (max-width: 1439px) {
  .actions {
    &__see-more-button {
      min-width: 210px;
    }

    &__confirm-button {
      min-width: 250px;
    }
  }
}

@media (max-width: 767px) {
  .cookie-consent {
    padding: 14px 20px 20px;
    min-height: 60px;
    flex-direction: column;
    align-items: flex-start;

    &__short-description,
    &__full-description {
      margin: 0 0 20px 0;
      max-width: none;

      font-size: 12px;
      line-height: 14px;
    }
  }
}

@media (max-width: 550px) {
  .actions {
    width: 100%;
    flex-direction: column;

    &__confirm-button {
      margin: 0;
    }
  }
}
</style>
